<template  lang="pug">
	v-container
		v-row( align="center"  justify="center" )
			v-col(cols="12" sm="6")
				v-dialog(v-model="dialog_spc" width="500")
					v-card
						v-card-title(
							class="headline grey lighten-2"
							primary-title
						) Segmentador y Priorizador de Clientes.
						v-card-text
							p(class="mt-5") El <span class="font-weight-bold">  objetivo </span>  es segmentar los clientes en grupos basados en su historial de compra.
							p El  <span class="font-weight-bold"> alcance </span> del proyecto es generar esa segmentación únicamente a los clientes que han comprado a través de canales digitales.   El <span class="font-weight-bold">  funcionamiento  </span> consiste en segmentar a los clientes basados en la probabilidad de que hagan una compra en el periodo en curso.  Esta probabilidad es calculada usando algoritmos de aprendizaje automático como regresión logística. 
							p La validación del modelo se realizó a través del cálculo de medidas como la precisión, AUC-ROC y P-R Score.

						v-divider
						v-card-actions
							v-spacer
							v-btn(
								color="blue-500"
								class="text-none"
								dark
								@click="dialog_spc = false") Cerrar
				v-dialog(v-model="dialog_rfm" width="500")
					v-card
						v-card-title(
							class="headline grey lighten-2"
							primary-title
						) RFM
						v-card-text
							p(class="mt-5") El análisis consiste en clasificar a los clientes por su valor en función de tres variables:
							p <span class="font-weight-bold"> Recencia. </span> Días transcurridos desde la última compra.
							p <span class="font-weight-bold"> Frecuencia. </span> Número de compras por período de tiempo, como promedio. Por ejemplo, número de compras mensuales.
							p <span class="font-weight-bold"> Money. </span> Valor de las compras totales realizadas por el cliente en el tiempo de análisis.
					
						v-divider
						v-card-actions
							v-spacer
							v-btn(
								color="blue-500"
								class="text-none"
								dark
								@click="dialog_rfm = false") Cerrar

		v-row( align="center"  justify="center"  class="mt-5")
			v-col(cols="12")
				p(class="title blue-700--text font-weight-black text-left mb-0") Clientes por día*
				div(id="sales" style="width: 100%;height: 500px;")
			v-col(cols="12" sm="10")
				p(class="caption blue-600--text") *Clientes con al menos una transación de  <span class="font-weight-black">2012-06-15  al 2019-11-27</span>
		v-row( justify="center"  class="text-right")
			v-col(cols="12" class="text-right")
				//- v-tooltip( top)
				//- 	template( v-slot:activator="{ on }")
				v-btn( icon v-on="on" x-small class="blue-600" @click="dialog_spc = true ")
					v-icon( color="white") mdi-help
					//- span Ver más
				p(class="title blue-700--text font-weight-black text-left mb-0") Segmentador y Priorizado  de Clientes (SPC)
				div(id="pie" style="width: 100%;height: 400px;" class="pt-2")

			v-col(cols="12" class="text-right graph-customize" )
				p(class="title blue-700--text font-weight-black text-left mb-0") Importancia VS Clientes VS Venta (RFM)
				//- v-tooltip( top)
				//- 	template( v-slot:activator="{ on }")
				v-btn( icon v-on="on" x-small class="blue-600" @click="dialog_rfm = true ")
					v-icon( color="white") mdi-help
					//- span Ver más

				div(id="rfm_score2" style="width: 100%;height: 500px;" class="pt-0")
				p(class="body-2 blue-700--text text-center font-weight-black") Importancia
				p(class="body-2 blue-700--text text-center font-weight-black labelGraph" ) Clientes
</template>
<script>
	var echarts = require('echarts');


	import GraphService  from '../../services/GraphService.js'
	const graphService = new GraphService();

	export default {
		name: 'DashboardAnalytics',
		data: () => ({

			dialog_rfm : false,
			dialog_spc : false ,

			pie : {
				title : {
					text: '',
					padding: 5,
					subtext: '',
					x:'left'
				},
				tooltip : {
					trigger: 'item',
					formatter: "{a} <br/>{b} : {c} ({d}%)"
				},
				legend: {
					orient: 'vertical',
					left: 'left ',
					data: [
						// 'C1 Retención con alta probabilidad de compra',
						// 'C2 Retención con baja probabilidad de compra',
						// 'C3 Recuperación con alta probabilidad de compra',
						// 'C4 Recuperación baja probabilidad de compra'

						]
				},
				series : [
					{
						name: 'SPC',
						type: 'pie',
						radius : '55%',
						center: ['50%', '60%'],
						data:[],
						itemStyle: {
							emphasis: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				],
				color:[ "#002842","#003960","#00548C","#006FB9","#EBF4FA"]
			},

			// sales
			sales : {
				title: {
					text: '',
					left: 10
				},
				toolbox: {
					feature: {
						dataZoom: {
							title:'',
							yAxisIndex: false
						},
						saveAsImage: {
							title:'C',
							pixelRatio: 2
						}
					}
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				grid: {
					bottom: 90
				},
				dataZoom: [{
					type: 'inside'
				}, {
					type: 'slider'
				}],
				xAxis: {
					data: [],
					silent: false,
					splitLine: {
						show: false
					},
					splitArea: {
						show: false
					}
				},
				yAxis: {
					splitArea: {
						show: false
					}
				},
				series: [{
					type: 'bar',
					data: [],
					// Set `large` for large data amount
					large: true
				}],
				color:[ "#002842","#003960","#00548C","#006FB9","#EBF4FA"]
			},

			// taza de recompra
			recompra: {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				toolbox: {
					show : true,
					feature : {
						mark : {title:'', show: false},
						dataView : {title:'',show: false, readOnly: false},
						magicType: {title:'',show: true, type: ['line', 'bar']},
						restore : {title:'',show: false},
						saveAsImage : {title:'',show: false}
					}
				},
				calculable : true,
				legend: {
					data:['Growth', 'Budget 2011', 'Budget 2012'],
					itemGap: 5
				},
				grid: {
					top: '12%',
					left: '1%',
					right: '10%',
					containLabel: true
				},
				xAxis: [
					{
						type : 'category',
						data : []
					}
				],
				yAxis: {
					splitArea: {
						show: false
					}
				},
				dataZoom: [{
					type: 'inside'
				}, {
					type: 'slider'
				}],
				series : [
					{
						name: 'Cliente Nuevo',
						type: 'bar',
						data: []
					},
					{
						name: 'Cliente Recompra',
						type: 'bar',
						data: []
					}
				],
				color:[ "#006FB9","#003960","#00548C","#006FB9","#EBF4FA"]
			},
			//RFM

			rfm_score2:{
				title : {
					text: '',
					padding: 10,
					subtext: '',
					x:'left'
				},
				legend: {
					orient: 'horizontal',
					left: 'left',
				},
				xAxis: [
					{type: 'category', gridIndex: 0}
				],
				yAxis: {
					splitLine: {
						lineStyle: {
							type: 'dashed'
						}
					},
					scale: true
				},
				series: [
					{
						name:'Venta',
						data: [],
						type: 'scatter',
						symbolSize: function (data) {
							return Math.sqrt(data[2]) / 5e2;
						},
						label: {
							emphasis: {
								show: true,
								formatter: function (param) {
									return param.data[3];
								},
								position: 'top'
							}
						},
						itemStyle: {
							normal: {
								shadowBlur: 10,
								shadowColor: '#006fb9)',
								shadowOffsetY: 5,
								color: '#002842'
							}
						}
					}
				]
			},

		}),

		mounted(){
			this.getDataSales();
			this.getDataPie();
			this.getScoreRFM();

		},
		methods:{
			getDataPie: function(){
				graphService.pieSpc()
				.then((response)=>{
					this.pie.series[0].data = response.data.values;
					this.pie.title.subtext = 'Clientes procesados '+this.formatNumber(response.data.total) +' de '+this.formatNumber(response.data.totalClientes)

					var pieG = echarts.init(document.getElementById('pie'));
					pieG.setOption(this.pie)
					pieG.on('click', params =>{
						let item = params.name
						item =  item.substring(0,item.indexOf(':'));
						console.log(item)
						this.$router.push({name: 'segmentador',params:{segment:'spc',item:item}})
					});

				})
				.catch((error)=>{
					console.log("Error: No connect with graph ")
				})
			},
			getScoreRFM: function(){

				graphService.rfmScore()
				.then((response)=>{
					// dos

					this.rfm_score2.series[0].data = response.data.data;

					var rfm_score2 = echarts.init(document.getElementById('rfm_score2'));
					rfm_score2.setOption(this.rfm_score2)
					rfm_score2.on('click', params =>{
						let item = params['data'][3]
						item =  item.substring(0,item.indexOf(':'));
						this.$router.push({name: 'segmentador',params:{segment:'rfm',item:item}})
					});

				})
				.catch((error)=>{
					console.log("Error: No connect with graph ")
				})

			},
			getDataSales: function(){

				graphService.sales()
				.then((response)=>{
					this.sales.xAxis.data = response.data.fechas;
					this.sales.series[0].data = response.data.suma;

					var sales = echarts.init(document.getElementById('sales'));
					sales.setOption(this.sales)

				})
				.catch((error)=>{
					console.log("Error: No connect with graph ")
				})
			},
			getTazaRecompra: function(){
				// this.pie.series[0].data = response.data.values;
				// this.pie.title.subtext = 'Clientes procesados '+this.formatNumber(response.data.total) +' de '+this.formatNumber(response.data.totalClientes)


				// axios.get('http://34.68.52.99:8000/spc/graph-clientes-nuevos')
				// .then((response)=>{
				// 	this.recompra.xAxis[0].data = response.data.names;
				// 	this.recompra.series[0].data = response.data.unicas;
				// 	this.recompra.series[1].data = response.data.acumuladas;

				// 	var recompra = echarts.init(document.getElementById('recompra'));
				// 	recompra.setOption(this.recompra)

				// })
				// .catch((error)=>{
				// 	console.log("Error: No connect with graph ")
				// })

			},
			formatNumber(valor){
				let	num= parseInt(valor);
				return  num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
			}





		}

	}
</script>
<style lang="sass">
	.graph-customize
		position: relative
		.labelGraph
			position: absolute
			margin: 0
			top: 50%
			left: 0
			-webkit-transform: rotate(90deg)
			-moz-transform: rotate(90deg)
			-o-transform: rotate(90deg)
			-ms-transform: rotate(90deg)
			transform: rotate(-90deg)

</style>
import BackendAuthService from "./BackendAuthService";

export default class GraphService extends BackendAuthService {

	constructor() {
		super();
	}

	sales(){
		return this.httpCall('interfaz/spc/graph-sales/',{
			method: 'GET',
		});
	}

	pieSpc(){
		return this.httpCall('interfaz/spc/graph-pie/', {
			method: 'GET',
		}); 

	}

	rfmScore(){
		return this.httpCall('interfaz/rfm/score/', {
			method: 'GET',
		}); 

	}




}

